<template>
  <div class="h-full flex flex-col items-center">
    <div v-if="qrCode" class="flex flex-col mb-4 text-center">
      <img :src="qrCode" class="border rounded-lg" />
      <div class="text-center mt-2">
        <p>{{ $t("builder_publish.scan_website") }}</p>
        <a
          download="qrcode.png"
          :href="qrCode"
          class="text-blue-500 hover:text-blue-600"
        >
          {{ $t("builder_publish.download") }}
        </a>
      </div>
    </div>
    <div class="w-full">
      <b-button
        icon-right="angle-right"
        size="is-large"
        type="is-dark"
        expanded
        :loading="publishing"
        @click="publish"
      >
        {{ $t("builder_publish.publish") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import ApiSites from "@/services/sites";
import * as Sentry from "@sentry/browser";
import { encryptText } from "@/utils/crypto";
import QRCode from "qrcode";

export default {
  name: "BuilderPublish",

  data() {
    return {
      publishing: false,
      qrCode: ""
    };
  },

  computed: {
    isAgency() {
      return this.$store.getters["isAgency"];
    },
    websiteUrl() {
      return this.$store.getters["builder/websiteUrl"];
    }
  },

  mounted() {
    this.generateQR();
  },

  methods: {
    async publish() {
      try {
        this.publishing = true;
        const config = await this.makeWebsiteConfig();
        if (!config.netlifyId) {
          await ApiSites.launchV2(config);
        } else {
          await ApiSites.launch(config);
        }
        this.$router.push({ name: "dashboard" }).catch(() => {});
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        this.publishing = false;
      }
    },

    async makeWebsiteConfig() {
      const config = this.$store.state.builder;
      const user = this.$store.state.user;
      let cipheredStripePrivateKey = config.paymentCfg.stripeCfg.privateKey;
      let cipheredStripePublicKey = config.paymentCfg.stripeCfg.publicKey;
      if (
        config.paymentCfg.stripeCfg.privateKey !== "" &&
        config.paymentCfg.changedKeys
      ) {
        cipheredStripePrivateKey = await encryptText(
          config.paymentCfg.stripeCfg.privateKey,
          process.env.VUE_APP_CIPHER_CLIENT_STRIPE_KEY
        );
      }
      if (
        config.paymentCfg.stripeCfg.publicKey !== "" &&
        config.paymentCfg.changedKeys
      ) {
        cipheredStripePublicKey = await encryptText(
          config.paymentCfg.stripeCfg.publicKey,
          process.env.VUE_APP_CIPHER_CLIENT_STRIPE_KEY
        );
      }
      const websiteConfig = {
        version: config.version,
        websiteId: config.websiteId,
        name: config.siteName,
        apiUrl: process.env.VUE_APP_WEBSITE_API,
        netlifyId: config.netlifyId,
        domain: config.domain,
        subdomain: config.subdomain,
        uid: config.userAdmin.id || user.uid,
        gaId: config.gaId,
        logo: config.logo,
        favicon: config.favicon,
        sheet: {
          id: config.sheetId,
          sheet: config.sheetName
        },
        metaFields: {
          homeDescription: config.metaFields.homeDescription,
          homeTitle: config.metaFields.homeTitle,
          itemDescription: config.metaFields.itemDescription,
          itemTitle: config.metaFields.itemTitle
        },
        listView: {
          hero: config.listItemConfig.hero,
          topContent: config.listItemConfig.topContent,
          caption: config.listItemConfig.captionField,
          description: config.listItemConfig.descriptionField,
          filterBy: config.listItemConfig.filterByField,
          image: config.listItemConfig.imageField,
          title: config.listItemConfig.titleField,
          cta: config.listItemConfig.ctaField,
          custom: config.listItemConfig.customFields
        },
        detailView: {
          caption: config.detailConfig.captionField,
          description: config.detailConfig.descriptionField,
          hide: config.detailConfig.hide,
          image: config.detailConfig.imageField,
          title: config.detailConfig.titleField,
          cta: config.detailConfig.ctaField,
          custom: config.detailConfig.customFields
        },
        paymentCfg: {
          changedKeys: config.paymentCfg.changedKeys,
          websiteId: config.websiteId,
          paymentMethod: "stripe",
          active: config.paymentCfg.active,
          currency: config.paymentCfg.selectedCurrency,
          textButtonPay: config.paymentCfg.textButtonPay,
          stripePublicKey: cipheredStripePublicKey,
          stripePrivateKey: cipheredStripePrivateKey
        },
        footer: config.footer,
        checkout: config.checkout,
        productOptions: config.productOptions,
        theme: config.theme,
        pwa: config.pwa,
        biewConfig: config.biewConfig,
        fixedNav: config.fixedNav || false
      };

      if (this.isAgency && config.demo) {
        websiteConfig.demo = true;
      }
      return websiteConfig;
    },

    async generateQR() {
      try {
        const qrCode = await QRCode.toDataURL(this.websiteUrl, {
          margin: 2,
          width: 150
        });
        this.qrCode = qrCode;
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }
};
</script>
