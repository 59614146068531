<template>
  <div
    class="builder bg-white grid grid-cols-12 divide-x divide-gray-400 h-screen"
  >
    <div class="col-span-4 max-h-screen overflow-hidden relative pb-16">
      <div class="flex justify-between items-center p-6 border-b">
        <div class="text-2xl font-bold">
          {{ sectionTitle }}
        </div>
        <div>
          <b-tooltip :label="$t('builder.sync_spreadsheet')" position="is-left">
            <b-button
              v-show="showSyncSheet"
              size="is-small"
              class="w-8"
              :class="{ 'mr-4': !isPro }"
              :disabled="loadingSheetItems"
              @click="getSheetItems"
            >
              <b-icon :class="{ spin: loadingSheetItems }" name="sync-alt" />
            </b-button>
          </b-tooltip>
          <b-button
            v-show="!isPro && !isAdmin"
            size="is-small"
            type="is-dark"
            class="text-center font-medium text-gray-700"
            @click="showPaymentModalActive = true"
          >
            {{ $t("builder.upgrade_to_pro") }}
          </b-button>
        </div>
      </div>
      <div class="step-container container mx-auto flex h-full mb-16">
        <div class="w-full px-6 pt-6 pb-10 overflow-y-auto">
          <builder-insert-sheet
            v-if="activeStep === 0"
            @sheet-id-change="getSheet($event)"
          />
          <builder-website-config
            v-else-if="activeStep === 1"
            @open-payment-modal="showPaymentModalActive = true"
          />
          <builder-list-item-config v-else-if="activeStep === 2" />
          <builder-detail-config v-else-if="activeStep === 3" />
          <builder-checkout v-else-if="activeStep === 4" />
          <builder-integrations
            v-else-if="activeStep === 5"
            @open-payment-modal="showPaymentModalActive = true"
          />
          <builder-seo v-else-if="activeStep === 6" />
          <builder-publish v-else-if="activeStep === 7" />
        </div>
      </div>
      <div class="absolute z-10 bottom-0 w-full bg-gray-200">
        <trial-alert
          v-show="showTrialBadge"
          :website-id="websiteId"
          :created-at="createdAt"
          @upgrade="showPaymentModalActive = true"
        />
        <div class="container flex justify-between items-center p-4">
          <b-button
            class="font-semibold"
            icon-left="angle-left"
            :disabled="activeStep <= 0"
            @click="changeStep(activeStep - 1)"
          >
            {{ $t("builder.back") }}
          </b-button>
          <div class="font-bold">{{ activeStep + 1 }} / {{ maxSteps + 1 }}</div>
          <b-button
            class="font-semibold"
            icon-right="angle-right"
            :disabled="disableNextStep"
            @click="changeStep(activeStep + 1)"
          >
            {{ $t("builder.next") }}
          </b-button>
        </div>
      </div>
    </div>
    <div
      id="preview"
      class="
        col-span-8
        bg-gray-100
        text-gray-600
        min-h-screen
        w-full
        overflow-y-auto
      "
    >
      <builder-preview />
    </div>
    <payment-modal
      v-show="showPaymentModalActive"
      :website-id="websiteId || ''"
      :website-url="domain || ''"
      @close="showPaymentModalActive = false"
      @payment-success="upgradeSite"
    />
  </div>
</template>

<script>
import BuilderCheckout from "./BuilderCheckout";
import BuilderDetailConfig from "./BuilderDetailConfig";
import BuilderInsertSheet from "./BuilderInsertSheet";
import BuilderIntegrations from "./BuilderIntegrations";
import BuilderListItemConfig from "./BuilderListItemConfig";
import BuilderPreview from "@/views/BuilderPreview/BuilderPreview";
import BuilderSeo from "./BuilderSeo";
import BuilderPublish from "./BuilderPublish";
import BuilderWebsiteConfig from "./BuilderWebsiteConfig";
import BIcon from "@/components/BIcon";
import PaymentModal from "@/components/PaymentModal/PaymentModal";
import TrialAlert from "@/components/TrialAlert";
import * as builderMutation from "@/store/modules/builder/mutation-types";
import * as checkoutMutation from "@/store/modules/checkout/mutation-types";
export default {
  name: "Builder",

  components: {
    BIcon,
    BuilderCheckout,
    BuilderDetailConfig,
    BuilderInsertSheet,
    BuilderIntegrations,
    BuilderListItemConfig,
    BuilderPreview,
    BuilderPublish,
    BuilderSeo,
    BuilderWebsiteConfig,
    PaymentModal,
    TrialAlert
  },

  data() {
    return {
      activeStep: 0,
      loadingSheetItems: false,
      maxSteps: 7
    };
  },

  computed: {
    disableNextStep() {
      if (this.activeStep === 1 && !this.domain) {
        return true;
      } else {
        return this.activeStep >= this.maxSteps || !this.items.length;
      }
    },
    domain() {
      return this.$store.state.builder.domain;
    },
    isPro() {
      return this.$store.state.builder.isPro;
    },
    items() {
      return this.$store.state.builder.items;
    },
    isShoppingCartEnabled() {
      return this.$store.getters["builder/isShoppingCartEnabled"];
    },
    websiteId() {
      return this.$store.state.builder.websiteId;
    },
    sectionTitle() {
      switch (this.activeStep) {
        case 0:
          return this.$t("builder.insert_spreadsheet");
        case 1:
          return this.$t("builder.section_title.website");
        case 2:
          return this.$t("builder.section_title.list_page");
        case 3:
          return this.$t("builder.section_title.single_page");
        case 4:
          return this.$t("builder.section_title.checkout_form");
        case 5:
          return this.$t("builder.section_title.integrations");
        case 6:
          return this.$t("builder.section_title.seo");
        case 7:
          return this.$t("builder.section_title.publish");
        default:
          return this.$t("builder.section_title.config");
      }
    },
    showSyncSheet() {
      return this.activeStep !== 0 && this.activeStep !== this.maxSteps;
    },
    createdAt() {
      return new Date(this.$store.state.builder?.createdAt?.seconds * 1000);
    },
    showTrialBadge() {
      return this.$store.getters["builder/showTrialBadge"];
    },
    fontSelected: {
      get() {
        return this.$store.state.builder.theme.font;
      }
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    }
  },

  watch: {
    activeStep(newVal, oldVal) {
      if (newVal === 4 && this.isShoppingCartEnabled) {
        this.$store.commit(
          `checkout/${checkoutMutation.SHOW_SHOPPING_CART}`,
          true
        );
        this.$store.commit(
          `checkout/${checkoutMutation.SHOW_CHECKOUT_FORM}`,
          true
        );
      } else if (newVal === 4 && !this.isShoppingCartEnabled) {
        this.activeStep = newVal > oldVal ? 5 : 3;
      } else {
        this.$store.commit(
          `checkout/${checkoutMutation.SHOW_SHOPPING_CART}`,
          false
        );
        this.$store.commit(
          `checkout/${checkoutMutation.SHOW_CHECKOUT_FORM}`,
          false
        );
      }
    }
  },

  beforeDestroy() {
    this.$store.dispatch("builder/resetState");
  },
  mounted() {
    if (this.fontSelected && this.fontSelected.cdn) {
      const fileref = document.createElement("link");
      fileref.rel = "stylesheet";
      fileref.type = "text/css";
      fileref.href = this.fontSelected.cdn;
      document.getElementsByTagName("head")[0].appendChild(fileref);
      document.getElementById(
        "preview"
      ).style.fontFamily = `'${this.fontSelected.name}'`;
    }
  },

  methods: {
    async getSheetItems() {
      try {
        this.loadingSheetItems = true;
        await this.$store.dispatch("builder/getSheetItems");
      } catch (error) {
        this.$buefy.notification.open({
          message: error.message || "Error trying to synchronize spreadsheet",
          type: "is-danger"
        });
      } finally {
        this.loadingSheetItems = false;
      }
    },
    changeStep(step) {
      if (step === 3 && this.$route.name !== "preview-detail-view") {
        this.$router
          .push({
            name: "preview-detail-view",
            params: { slug: this.items[0].slug }
          })
          .catch(() => {});
      } else if (step === 2 && this.$route.name !== "preview-list-view") {
        this.$router
          .push({
            name: "preview-list-view"
          })
          .catch(() => {});
      }

      if (step === 2) {
        this.updateSubdomain();
      }

      this.activeStep = step;
    },
    upgradeSite() {
      this.$store.commit(`builder/${builderMutation.SET_IS_PRO}`, true);
    },
    updateSubdomain() {
      this.$store.dispatch(
        "builder/assignDomain",
        this.$store.state.builder.subdomain
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.builder {
  height: 100vh;
}

.step-container {
  padding-bottom: 68px;
}

.spin {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
